import clock from '../../asstes/clock.svg';
import coins from '../../asstes/coin-stack.svg';
import pencil from '../../asstes/pencil.svg';
import focus from '../../asstes/focus.svg';
import arrow from '../../asstes/arrow-noth-east.svg'

export const Section4 = () => {
    return <section className="section4" id='Discover' >
        <div className="header"> 
            <div className="head" > Why Should I use it? 🤔 </div>
            <p className='smallText'>To turn any idea into perfect output, instantly  🚀</p>
         </div>

        <div className="cards-container" > 
            <div className="card" >
                <img src={clock} alt='save 200+ hours' />
                <div className="text-content" >
                    <div className="head" >  3000+ Projects </div>
                    <br></br>
                    <div className="text" >
                    Over 3000 projects completed, saving you time and money while turning dreams into reality. Join our satisfied clients and experience success redefined.
                    </div>
                </div>
            </div>
        
            <div className="card" >
                <img src={coins} alt='Make more $$$' />
                <div className="text-content" >
                    <div className="head" > Save more $$$ </div>
                    <br></br>
                    <div className="text" >
                    Save big with our comprehensive services, conveniently consolidating all your needs under one roof. Unlock substantial savings as we streamline your expenses and maximize your financial efficiency.​
                    </div>
                </div>
            </div>
        
            <div className="card" >
                <img src={pencil} alt='4500+ prompts' />
                <div className="text-content" >
                    <div className="head" > 2000+ Clients </div>
                    <br></br>
                    <div className="text" >
                  It Cover all the areas for:
                    <br></br>
✅ Web Services  
<br></br>
✅ AI Services 
<br></br>
✅ Digital Solutions 
<br></br>
✅ Video Production  
<br></br>
✅ Event Management

                    </div>
                </div>
            </div>
        
            <div className="card" >
                <img src={focus} alt='Everything in one place' />
                <div className="text-content" >
                
                    <div className="head" > Everthing in one Place </div>
                    <br></br>
                    <div className="text">
                    Experience convenience at its finest with our all-in-one platform. Find all your desired services in a single place, eliminating the hassle of dealing with multiple providers. Simplify your life and fulfill your requirements effortlessly with our comprehensive solution.
                    </div>
                </div>
            </div>

        </div>

        {/* <button className='explore-btn' >
            Explore Prompts <img src={arrow} alt='pointing arrow' />
        </button> */}

    </section>
}