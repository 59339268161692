import arrow from '../../asstes/arrow-noth-east.svg';
import twoArrow from '../../asstes/two-arrows.svg';
import gif from '../../asstes/gif.gif'
import firebond from '../../asstes/Firebond.png'

export const Section3 = () => {
  return <section className="section3" id='preview' >

    <div className='container1' >
      <div className='heading' > Our Work 👇  </div>
    </div>

    {/* <div className='blurred-container' >
            <div className='heading' > Become A Master At ChatGPT </div>
            <div className='text' > PromptBundle is totally free. So, you can save Money as well as time. 😉 </div>

            <div className='btn-container' >  
                <button> Explore Prompts <img src={arrow} alt='pointing arrow' /> </button>
            </div>
            
        </div> */}

    {/* <img className='gif'  src="https://youtu.be/g52FIZ5Tn44" alt='short glimpse of tool' /> */}
    <div className="gif">
      <iframe
        width="853"
        height="480"
        src={`https://www.youtube.com/embed/Q8dfeJP6vD8`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
      <br />
      <iframe
        width="853"
        height="480"
        src={`https://www.youtube.com/embed/N3r6dC7EOEU`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
      <br />
      <iframe
        width="853"
        height="480"
        scrolling="no"
        src={`https://superteam.fun`}
        frameBorder="0"
      />
    
      <br />


    </div>

    <div className='bottom' >
      <img className='two-arrows' src={twoArrow} alt='pointing arrow' />
      <a href='mailto:vibhupandey.vp@gmail.com'>
        <button> Contact Us <img src={arrow} alt='pointing arrow' /> </button></a>
      {/* <div className='text' >
            PromptBundle is totally free. ⚡ <br />
            So, you can save Money as well as time. 😉
            </div> */}
    </div>

  </section>
}
