import img1 from '../../asstes/img1.svg'
import img2 from '../../asstes/img2.svg'
import img3 from '../../asstes/img3.svg'
import img4 from '../../asstes/img4.svg'
import img5 from '../../asstes/img5.svg'
import img6 from '../../asstes/img6.svg'
import img7 from '../../asstes/img7.svg'
import img8 from '../../asstes/img8.svg'


export const Section2 = () => {
    return <section className='section2' >
        <h1 className='sub-head' >
        Our top clients 🔥
        </h1>

        <div className='container' >
            <div className='container-hidden' >
            <div className="creator-box" >
                <img src={img1} alt='creator' />
                <span> Hero MotoCoro </span>
            </div>
            <div className="creator-box" >
                <img src={img2} alt='creator' />
                <span> TVS Motors </span>
            </div>
            <div className="creator-box" >
                <img src={img3} alt='creator' />
                <span> ClickUp </span>
            </div>
            <div className="creator-box" >
                <img src={img4} alt='creator' />
                <span> Firebond </span>
            </div>
            <div className="creator-box" >
                <img src={img5} alt='creator' />
                <span> Cremica </span>
            </div>
            <div className="creator-box" >
                <img src={img6} alt='creator' />
                <span> Petronas India </span>
            </div>
            <div className="creator-box" >
                <img src={img7} alt='creator' />
                <span> Sunya Dot Ek </span>
            </div>
            <div className="creator-box" >
                <img src={img8} alt='creator' />
                <span> B2B Records INC. </span>
            </div>
            
            </div>
        </div>

    </section>
}