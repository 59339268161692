import { useEffect, useState } from "react";
import cryso from "../../asstes/cryso.svg";
import { RiMenu3Fill } from "react-icons/ri"
import {MdClose} from "react-icons/md"
export const Navigation = () => {
  const [date, setDate] = useState(new Date());
  const locale = "en";

  const [hamburgerMenudisplay, setHamburgerMenudisplay] = useState({
    display: "none",
  });
  const [hamburgerDisplay, setHamburgerDisplay] = useState({});

  function scrollToPreview() {
    document.getElementById("Discover").scrollIntoView({ behavior: "smooth" });
  }
  useEffect(() => {
    const timer = setTimeout(() => {
      setDate(new Date());
    }, 1000);
    return () => {
      clearInterval(timer); // Return a funtion to clear the timer so that it will stop being called on unmount
    };
  });

  const handleHamburger = () => {
    setHamburgerDisplay({ display: "none" });
    setHamburgerMenudisplay({ display: "flex" });
  };

  const handleHamburgerCross = () => {
    setHamburgerDisplay({ display: "flex" });
    setHamburgerMenudisplay({ display: "none" });
  };

  return (
    <div>
      <nav>
        <div className="nav-head" style={{ marginRight: "-1rem" }}>
          {new Date().getFullYear()}
          <p className="time">{date.toLocaleTimeString()}</p>
        </div>
        <div className="nav-logo">
          <img src={cryso} alt="logo" />
        </div>

        <div className="nav-links">
          <span onClick={scrollToPreview}> Explore </span>
          <a href="https://0d7kewifhqx.typeform.com/to/KYhWPMdW" target="_black">
            {" "}
            Connect{" "}
          </a>
          <a href="mailto:vibhupandey.vp@gmail.com"> Buzz Us </a>
        </div>
        <div
          className="hamburger"
          onClick={handleHamburger}
          style={hamburgerDisplay}
        >
          <RiMenu3Fill />
        </div>


        <div style={hamburgerMenudisplay} className="hamburger-menu">
          <span onClick={scrollToPreview}> Explore </span>
          <a href="https://0d7kewifhqx.typeform.com/to/KYhWPMdW" target="_black">
            {" "}
            Connect{" "}
          </a>
          <a href="mailto:vibhupandey.vp@gmail.com"> Buzz Us </a>
          <div className="hamburger-cross" onClick={handleHamburgerCross}>
          <MdClose/>
          </div>
        </div>
      </nav>
    </div>
  );
};
