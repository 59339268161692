import arrow from '../../asstes/arrow-noth-east.svg';
import star from '../../asstes/star.svg';

export const Section1 = () => {
    return <section className='section-1' >
        <div className='heading' >
             <div> Discover The Best</div>
            <div className='header-line-2' > 
            <div className='vertical-scroll-container' > 
                <div className='vertical-scroll-hidden' >
                    <span className='word' > Web Services </span>
                    <span className='word' > AI Integration </span>
                    <span className='word' > Digital Designing </span>
                    <span className='word' > Video Services </span>
                    <span className='word' > Event Handling </span>
                </div>
                </div> 
                For Your Brand 
                </div>
        </div>

        <div className='small-text' >
        <p>Make your imagination a reality using</p>
        our team of Top Video Producers and WEB leaders
        </div>

        <div className='btn-container' >
        <a href='https://0d7kewifhqx.typeform.com/to/KYhWPMdW'>
        <span className="spark"></span>
        <button> Explore Services <img src={arrow} alt='pointing arrow' /> </button>
        </a>
            
        </div>

    </section>
}