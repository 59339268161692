import img1 from "../../asstes/img1.svg";
import vibhu from "../../asstes/vibhu.svg";
import soubhagya from "../../asstes/soubhagya.svg";
export const Section7 = () => {
  return (
    <section className="section7">
      <div className="sub-head">Our founders 😎</div>
      <div className="container">
        <div className="container-hidden">
          <div className="creator-box">
            <img src={vibhu} alt="creator" />
            {/* <span> Vibhu Pandey </span> */}
          </div>
          <div className="creator-box">
            <img src={soubhagya} alt="creator" />
            {/* <span> Soubhagya Upadhyay </span> */}
          </div>
        </div>
      </div>
			<div className='small-text' >
      <p>Leave your Dreams on us. ⚡</p>
			<p>So, you can save time as well as money. 🤑</p>

        </div>
    </section>
  );
};
