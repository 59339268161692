import {AiFillLinkedin,AiFillInstagram,AiFillYoutube} from 'react-icons/ai'
// import {RiProductHuntFill} from "react-icons/ri"
export const Footer = () => {
    return <footer>
        {/* <div className="footer-main" > */}
        <div className="head" > CRYSO </div>
        <div className="footer-links" >
            <a href='https://www.linkedin.com/in/cryso-media-106474288/' target="_blank" > <AiFillLinkedin/> </a>
            <a href='https://www.instagram.com/crysomedia/' target="_blank" > <AiFillInstagram/> </a>
            <a href='https://www.youtube.com/@crysomedia' target="_blank" > <AiFillYoutube/> </a>
        </div>
        <span className="designed-text"   > Designed By CRYSO </span>
        {/* </div> */}
    </footer>
}