
// import SimpleBar from 'simplebar-react';
export const Section5 =() => {
    return <section className="section5" >
        <div className='heading' > What our users have to say 🤟 </div>
        {/* <ScrollContainer> */}
        <div className="article-container" >
            <div className='article-container-hidden'>
        <article>
            <div className="article-text" >
                <div className="article-head" > Really liked the service of the Prompt Bundle!! </div>
                <div className="article-content" > It's like having a personal content creator right in my pocket. It sparks my creativity and helps me overcome writer's block effortlessly</div>
            </div>
            <div className="article-user" >
                {/* <img src={face} alt='article person' /> */}
                <div className="user-detail" >
                <span className="username" > Csaba Kissi</span>
                <span className="user-work" > Content Creator </span>
                </div>
            </div>

        </article>
        <article>
            <div className="article-text" >
                <div className="article-head" > Got everything at one place </div>
                <div className="article-content" > It is so awesome to get everything in one place. I am using it now on everyday basis. </div>
            </div>
            <div className="article-user" >
                {/* <img src={face} alt='article person' /> */}
                <div className="user-detail" >
                <span className="username" > David Will</span>
                <span className="user-work" > Entreprenuer </span>
                </div>
            </div>

        </article>
        <article>
            <div className="article-text" >
                <div className="article-head" > Easy to follow!!</div>
                <div className="article-content" > Really great and actionable with such an amazing output.  </div>
            </div>
            <div className="article-user" >
                {/* <img src={face} alt='article person' /> */}
                <div className="user-detail" >
                <span className="username" > Sarah Burner </span>
                <span className="user-work" > Business Consultant  </span>
                </div>
            </div>

        </article>
        <article>
            <div className="article-text" >
                <div className="article-head" > Great output </div>
                <div className="article-content" > I have been using it for 1 week now. It makes my content creation journey so easy I just have to input my data and it provides the answer. </div>
            </div>
            <div className="article-user" >
                {/* <img src={face} alt='article person' /> */}
                <div className="user-detail" >
                <span className="username" > Devin Harper  </span>
                <span className="user-work" > Creator </span>
                </div>
            </div>

        </article>
        {/* <article>
            <div className="article-text" >
                <div className="article-head" > Really liked the service of the Prompt Bundle!! </div>
                <div className="article-content" > It's like having a personal brainstorming assistant right in my project management tool. It sparks my creativity and helps me overcome writer's block effortlessly </div>
            </div>
            <div className="article-user" >
                <img src={face} alt='article person' />
                <div className="user-detail" >
                <span className="username" > Adam Smith </span>
                <span className="user-work" > Content Creator </span>
                </div>
            </div>

        </article>
        <article>
            <div className="article-text" >
                <div className="article-head" > Really liked the service of the Prompt Bundle!! </div>
                <div className="article-content" > It's like having a personal brainstorming assistant right in my project management tool. It sparks my creativity and helps me overcome writer's block effortlessly </div>
            </div>
            <div className="article-user" >
                <img src={face} alt='article person' />
                <div className="user-detail" >
                <span className="username" > Adam Smith </span>
                <span className="user-work" > Content Creator </span>
                </div>
            </div>

        </article> */}
        </div>
        </div>
        {/* </ScrollContainer> */}
    </section>
}